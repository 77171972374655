import React, { useEffect, useRef, useState } from "react";
import Popover from "@mui/material/Popover";
import s from "./styles.module.scss";
import filesFetcher from "../../Services/filesFetcher";
import Service from "./Service";
import { styled } from "@mui/system";

const StyledPopup = styled(Popover)(({ theme }) => ({
  "@media (max-width:940px)": {
    ".MuiPaper-root": {
      left: "50% !important",
      marginTop: "75px",
      transform: "translateX(-50%) !important",
      width: "max-content",
    },
  },
}));


const OtherServices = ({ className = "", ...rest }) => {
  const [isShowingServices, setIsShowingServices] = useState(false);
  const [error, setError] = useState(false);
  const [services, setServices] = useState([]);

  const containerRef = useRef(null);

  const handleClose = () => setIsShowingServices(false);
  const handleOpen = () => setIsShowingServices(true);

  const scrollEventHandler = (e) => {
    if (isShowingServices) {
      setIsShowingServices(false);
    }
  };

  useEffect(() => {
    
    const fetchServices = async () => {
      try {
        const servicesConfig = await filesFetcher.fetchOtherServices();

        setServices(servicesConfig);
      } catch (error) {
        console.error(`Error fetching services JSON: ${error}`);
        setError(error);
      }
    };

    fetchServices();

    return () => {
      document.removeEventListener("scroll", scrollEventHandler);
    };
    
  }, []);

  useEffect(() => {
    setTimeout(() => {
      document.body.style.overflow = 'visible';
      document.body.style.paddingRight  = 0;
    }, 0);

    if (isShowingServices) {
      document.addEventListener("scroll", scrollEventHandler, false);
    } else {
      document.removeEventListener("scroll", scrollEventHandler);
    }

  }, [isShowingServices]);

  return (
    <>
      <div
        onClick={handleOpen}
        className={s.services}
        ref={containerRef}
        {...rest}
      >
        <button className={`${s.services_btn} ${className} `}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1024px"
            height="1024px"
            viewBox="0 0 1024 1024"
          >
            <path d="M899.4 638.2h-27.198c-2.2-.6-4.2-1.6-6.4-2-57.2-8.8-102.4-56.4-106.2-112.199-4.401-62.4 31.199-115.2 89.199-132.4 7.6-2.2 15.6-3.8 23.399-5.8h27.2c1.8.6 3.4 1.6 5.4 1.8 52.8 8.6 93 46.6 104.4 98.6.8 4 2 8 3 12v27.2c-.6 1.8-1.6 3.6-1.8 5.4-8.4 52-45.4 91.599-96.801 103.6-5 1.2-9.6 2.6-14.2 3.8zM130.603 385.8l27.202.001c2.2.6 4.2 1.6 6.4 1.8 57.6 9 102.6 56.8 106.2 113.2 4 62.2-32 114.8-90.2 131.8-7.401 2.2-15 3.8-22.401 5.6h-27.2c-1.8-.6-3.4-1.6-5.2-2-52-9.6-86-39.8-102.2-90.2-2.2-6.6-3.4-13.6-5.2-20.4v-27.2c.6-1.8 1.6-3.6 1.8-5.4 8.6-52.2 45.4-91.6 96.8-103.6 4.8-1.201 9.4-2.401 13.999-3.601zm370.801.001h27.2c2.2.6 4.2 1.6 6.4 2 57.4 9 103.6 58.6 106 114.6 2.8 63-35.2 116.4-93.8 131.4-6.2 1.6-12.4 3-18.6 4.4h-27.2c-2.2-.6-4.2-1.6-6.4-2-57.4-8.8-103.601-58.6-106.2-114.6-3-63 35.2-116.4 93.8-131.4 6.4-1.6 12.6-3 18.8-4.4z" />
          </svg>
        </button>
        <span className={s.services_label}>Other Services</span>
      </div>
      <StyledPopup
        open={isShowingServices}
        anchorEl={containerRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        style={{ zIndex: '99999999'}}
        sx={{ marginTop: "12px" }}
      >
        {error ? (
          <span>Couldn't fetch other services. Please, try again.</span>
        ) : services.length ? (
          services.map((service) => (
            <Service key={service.value} service={service} />
          ))
        ) : (
          <span>Other services are coming soon!</span>
        )}
      </StyledPopup>
    </>
  );
};

export default OtherServices;
