import React, { useState } from 'react';
import { Box, TextField, Button, IconButton, Dialog,DialogContent, DialogTitle } from '@mui/material';
// import { createStyles, makeStyles } from '@mui/styles';
import { makeStyles } from "@material-ui/core/styles";
// import PropTypes from 'prop-types';
import xicon from '../../Assets/Icons/x-symbol-svgrepo-com.svg';
import CloseIcon from "@mui/icons-material/Close";
// const useStyles = makeStyles(() =>
//   createStyles({
//     root: {
//       "& .MuiPaper-root": {
//         overflow: "visible",
//         marginTop: '-10px'
//       },
//       "& .MuiFormControl-root": {
//         marginRight: '10px',
//         paddingRight: '10px'
//       }
//     },
//   }),
// );

const closeStyle = {
  position: "absolute",
  top: "-17px",
  right: "-17px",
  padding: "5px",
  background: "#fff",
  boxShadow: "0px 2px 3px #0000002b",
  width: "34px",
  height: "34px"
};

const imgStyle = {
  float: 'left',
  marginRight: 10,
  maxWidth: '32px',
  opacity: 0.6
};
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      overflow: "visible",
      padding: '12px 22px',
      top: 'calc(-50% + 200px)',
      maxWidth: '340px',
      minHeight: '180px'
    },
    "& .MuiFormControl-root": {
      paddingRight: '10px',
      width: '250px'
    },
        "& .MuiDialogContent-root": {
      overflow: 'visible'
    }
  },
  closeStyle: {
    "& :hover": {
      color: "#000",
    }
  }
}));
export const Modal = ({ onClose, onClick, show, image, header, country }) => {

  const [code, setCode] = useState('');
  const classes = useStyles();
  const handleInputChange = (e) => {
    setCode(e.target.value);
  }

  return (

     <Dialog
        open={show}
        className={classes.root}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
          style={closeStyle}
          className={classes.closeStyle}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id="alert-dialog-title">
          <img
            className="model-img"
            style={imgStyle}
            alt="geolocation"
            src={image}
          />
          {header}
        </DialogTitle>

        <DialogContent className={classes.root}>
          <form onSubmit={onClick}>
            <Box display="flex" alignItems="center">
              {/* <Box pr={2} flexGrow={1}> */}
                <TextField
                  fullWidth
                  size="small"
                  type="text"
                  name="postal_code"
                  label={
                    country === 'United States'
                      ? 'ZIP Code'
                      : 'Postal Code'
                  }
                  variant="outlined"

                  placeholder={
                    country === 'United States'
                      ? 'Enter ZIP Code'
                      : 'Enter Postal Code'
                  }

                  InputProps={{
                    inputProps: {
                      type: 'string',
                      minLength: 5,
                      maxLength: 7
                    }
                  }}

                  value={code}
                  onChange={handleInputChange}
                />
              {/* </Box> */}
              <Button type="submit" style={{backgroundColor: '#2c5e90', width: '120px' }} variant="contained">
                SUBMIT
              </Button>
            </Box>
          </form>
        </DialogContent>

      </Dialog>
  )
}


export default Modal;
