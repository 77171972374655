import React, { Component } from 'react';
import Geocode from 'react-geocode';
import { toast } from 'react-toastify';
import axios from 'axios';
import publicIp from 'public-ip';
import logo_black from '../../Assets/Icons/map-marker-black.png';
import logo from '../../Assets/Icons/map-marker.png';
import Modal from './Modal';
import { calculateRegion, getLocation } from './utils';

let defaultRegion = sessionStorage.getItem('region') ?? 'gta';
let defaultLocation = sessionStorage.getItem('location') ?? 'Toronto';
let defaultCity = 'Select City';
let defaultProvince = '';
let defaultCountry = sessionStorage.getItem('country') ?? 'Canada';
let defaultPostal = null;

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
// TODO: Move api keys to .env file and don't commit it!
const API_KEY = 'beaca91296ad435bb9b5a231fe1234f4';
Geocode.setApiKey('AIzaSyBk30RGZX1CCvyRA79tt8OCKfapXktL3E0');
Geocode.setLanguage('en');
Geocode.setRegion('CA');

class GeoLocation extends Component {
  constructor(props) {
    super(props);
    this.openLocateModal = this.openLocateModal.bind(this);
    this.changeRegionData = this.changeRegionData.bind(this);
    this.state = {
      data: this.props.data,
      isModalOpen: false,
      location: defaultLocation,
      region: defaultRegion,
      country: defaultCountry,
      city: defaultCity,
      province: defaultProvince,
      postal: defaultPostal,
    };
  }

  openLocateModal() {
    this.toggleModal();
  }

  async checkIPGeolocate() {
    const currentIp = await publicIp.v4();
    let newCountry = defaultCountry;

    const {
      data: {
        latitude,
        longitude,
        country_name
      }
    } = await axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=${API_KEY}&ip=${currentIp}`);

    if (!latitude || !longitude) {
      console.error('geoApi error');
    }

    if (country_name) {
      newCountry = country_name;
      sessionStorage.setItem('country', country_name);
    }

    this.updateCity(latitude, longitude);
    await this.changeRegionData(defaultRegion);

    this.setState({
      data: window.$json,
      lat: latitude,
      lng: longitude,
      country: newCountry,
      region: calculateRegion(latitude, longitude)
    });
  }

  async changeRegionData(region) {

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params && params.reg) region = params.reg;

    try {
      let json = await import('../../Assets/Data/' + region + '.json');
      window.$json = json;

      if (json?.location?.[0]?.country) {
        sessionStorage.setItem('country', json.location[0].country);

        this.setState({ country: json.location[0].country });
      }

      let { location, province, city } = getLocation(region);

      sessionStorage.setItem('location', location);
      sessionStorage.setItem('region', region);
      sessionStorage.setItem("countryCode", json.countryCode);

      this.props.setGeoData(json);

      if (params && params.loc) {
        var loc = params.loc.split('_');
        city = loc[0];
        province = loc[1];

        removeParam('loc');
        removeParam('reg');
        removeParam('postal');
      }

      this.setState({ city, province });

    } catch (err) {
      toast(err.message, { type: 'error' });
      console.log(err);
    }


    function removeParam(paramName) {
      let searchParams = new URLSearchParams(window.location.search);
      searchParams.delete(paramName);

      if (window.history.replaceState) {
        let searchString = searchParams.toString().length > 0 ? '?' + searchParams.toString() : '';
        let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + searchString + window.location.hash;
        window.history.replaceState(null, '', newUrl);
      }
    }
  }

  toggleModal = () => {
    this.setState({
      header: 'Change Your City',
      isModalOpen: !this.state.isModalOpen,
    });
  };

  onUpdate = async (e) => {
    e.preventDefault();

    const value = document.querySelector('[name="postal_code"]').value;

    try {
      await this.runGeocode(value);
    } catch (err) {
      toast(err.message, { type: 'error' });
      console.log(err);
    }

    this.toggleModal();
  };

  runGeocode = async (value) => {
    if (value.length > 0) {
      sessionStorage.setItem('postal', value);
      this.setState({ postal: value });
    }

    const response = await Geocode.fromAddress(value);
    const lng = response.results[0].geometry.location.lng;
    const lat = response.results[0].geometry.location.lat;

    this.setState({ lat, lng });

    const newRegion = await calculateRegion(lat, lng);

    await this.changeRegionData(newRegion);
    await this.updateCity(lat, lng);
  };

  updateCity = async (lat, long) => {
    let newProvince;
    let newCountry;
    let newCity;

    try {
      const response = await Geocode.fromLatLng(lat, long);

      for (let i = 0; i < response.results[0].address_components.length; i++) {
        for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
          switch (response.results[0].address_components[i].types[j]) {
            case 'locality':
              newCity = response.results[0].address_components[i].long_name;
              this.setState({ city: newCity });
              break;
            case 'administrative_area_level_1':
              newProvince = response.results[0].address_components[i].long_name;
              this.setState({ province: newProvince });
              break;
            case 'country':
              newCountry = response.results[0].address_components[i].long_name;
              this.setState({ country: newCountry });
              break;
            default:
              break;
          }
        }
      }
      sessionStorage.setItem("city", newCity);
      sessionStorage.setItem("province", newProvince);

      // if () {
        let jsonUpd = {...window.$json};
        jsonUpd.updData = { city: newCity, province: newProvince }
        this.props.setGeoData(jsonUpd);    
        sessionStorage.setItem("countryCode", jsonUpd.countryCode);
      // }


      this.props.updateUserLocation({
        location: [newCity, this.state.province],
        region: defaultRegion,
        postal: this.state.postal,
      });
    } catch (err) {
      toast(err.message, { type: 'error' });
      console.error(err);
    }
  };

  componentDidUpdate() {
    if (this.props.userLocationContact && this.state.postal !== this.props.userLocationContact) {
      this.runGeocode(this.props.userLocationContact);
      // this.toggleModal();
    }
  }

  componentDidMount() {

    if (!window.URLSearchParams)
      (function (w) {

        w.URLSearchParams = w.URLSearchParams || function (searchString) {
          var self = this;
          self.searchString = searchString;
          self.get = function (name) {
            var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(self.searchString);
            if (results == null) {
              return null;
            } else {
              return decodeURI(results[1]) || 0;
            }
          };
        }

      })(window)

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params && params.postal && params.postal !== 'null') {
      sessionStorage.setItem("postal", params.postal);
    }

    let newRegion;

    if (sessionStorage.getItem('postal')) {
      let value = sessionStorage.getItem('postal');
      this.runGeocode(value);
    } else {

      if (defaultRegion && window.$json !== null) {
        this.changeRegionData(defaultRegion);
      }

      if ('geolocation' in navigator) {
        let success = async ({ coords: { latitude, longitude } }) => {
          newRegion = calculateRegion(latitude, longitude);

          this.updateCity(latitude, longitude);
          await this.changeRegionData(newRegion);
          this.setState({
            data: window.$json,
            lat: latitude,
            lng: longitude,
          });
        };

        const error = () => this.checkIPGeolocate();

        navigator.geolocation.enableHighAccuracy = true;
        navigator.geolocation.getCurrentPosition(success, error);
      }
    }
  }

  render() {

    // console.error( 'geoApi this.state', this.state );
    
    return (
      <div>
        <div onClick={this.openLocateModal} className="message geolocation-tab">
          <img src={logo} alt="app logo"/>
          <span>{(this.state.postal ? (this.state.city + ' ' + this.state.province) : (this.state.country + ' ' + (this.state.city === 'Greater Toronto Area' ? this.state.province : this.state.city) ))}</span>
        </div>

        <Modal
          show={this.state.isModalOpen}
          header={this.state.header}
          image={logo_black}
          onClose={this.toggleModal}
          country={this.state.country}
          onClick={this.onUpdate}
        />
      </div>
    );
  }
}

export default GeoLocation;
