export const PHONE_NUMBER_FORMATTED = '1-(800) 253-7118';
export const PHONE_NUMBER = '18002537118';
 
const prodHosts = [
  'bathroomrenovations.com',
  'agm-renovations-dev.s3-website.ca-central-1.amazonaws.com',
  'designtool.d3z8k3n1c1bgz.amplifyapp.com',
];

export const ENV = prodHosts.includes(window.location.hostname) ? 'PRODUCTION' : 'DEV';
export const IS_PROD = ENV === 'PRODUCTION';
export const IS_DEV_ENV = ENV === 'DEV';
export const API = 'https://apidev.bathroomrenovations2.com'

export const PAYPAL_WARRANTY_PRODUCT_ID = 2;

//export const app-server-name: DEV_TORONTO
export const APP_SERVER_NAME_HEADER = 'app-server-name';


export const APP_SERVER_NAME = {
  LOCALHOST: 'LOCALHOST',
  DEV_TORONTO: 'DEV_TORONTO',
  TORONTO: 'TORONTO',
  OTTAWA: 'OTTAWA',
}

export const DEFAULT_TAX_RATE = 'ONTARIO';

export const AVAILABLE_CITIES = [
  { label: 'GTA Region', value: 'TORONTO' }, 
  { label: 'Greater Ottawa Region', value: 'OTTAWA' },
  { label: 'Metro Vancouver', value: 'TORONTO' },
  { label: 'Calgary Metropolitan Area', value: 'TORONTO' },
];

export const IS_DEV = IS_DEV_ENV;

export const PAYMENT_METHOD_CARD = 'CARD';
export const PAYMENT_METHOD_PAYPAL = 'PAYPAL';

export const FULLNAME_REGEX = /^[a-z ,.'-]+$/i;

export const NAME_MAX_LENGTH = 70;
export const ADDRESS_MAX_LENGTH = 250;

export const BRAINTREE_ERROR = {
  HOSTED_FIELDS_FIELDS_EMPTY: 'HOSTED_FIELDS_FIELDS_EMPTY',
  HOSTED_FIELDS_FIELDS_INVALID: 'HOSTED_FIELDS_FIELDS_INVALID',
}

export const DEFAULT_CURRENCY = 'CAD';
export const DEFAULT_ORDER_PRICE = 350;
export const BASE_PRICE = 29500;
export const INCREASE_PRICE_BY = 3000;
export const OLD_PRICE = BASE_PRICE + 3000;
export const TAX_RATE = 15;

export const GOOGLE_MAPS_API_KEY = 'AIzaSyDXUgeeW6V3i3escrMYduRgNbLTteK3dsI';

export const PAYPAL_BUTTON_ID = 'paypal-button';

export const BRAINTREE_PAYMENT_ENABLED = false;
// export const PAYPAL_CLIENT_ID = IS_PROD
//   ? 'AUHgOCbU_yvn_1d0Bu5vzuUQBUoTmenqICpWHi0XDb8UGACXBYq5bx7JXmtp-DWaALFREodpru2hQruL'
//   : 'Adabqi3VC6KVukPqJf7O50IYfTERdrKds3FFl5n6kRzFsDYnIN3-iwnP6FlbCbdt6cckbkZES5jDVuv1';
export const PAYPAL_CLIENT_ID = 'Adabqi3VC6KVukPqJf7O50IYfTERdrKds3FFl5n6kRzFsDYnIN3-iwnP6FlbCbdt6cckbkZES5jDVuv1';
export const PAYPAL_PAYMENT_ENDPOINT = '';

export const ZAPIER_ORDER_WEBHOOK_URL = 'https://hooks.zapier.com/hooks/catch/2915270/bocok3q';
export const ZAPIER_CALL_BACK_HOOK_URL = 'https://hooks.zapier.com/hooks/catch/2915270/bovb6s5';

export const PHONE_NUMBER_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

export const cloudStorageUrl = 'https://agm-landing.s3.us-east-2.amazonaws.com/websites/articlesImages';
export const defLinksArticles = [
  {
    "url": "https://ocnjdaily.com/agm-renovations-reviews-latest-trends-basement-remodeling/",
    "image": "/images/blog1.jpg"
  },
  {
    "url": "https://somerspoint.com/2023/09/25/agm-renovations-reviews-new-3d-bathroom-creator/",
    "image": "/images/blog2.jpg"
  },
  {
    "url": "https://seaislenews.com/agm-renovations-reviews-kitchen-renos-creating-heart-home/",
    "image": "/images/blog3.jpg"
  },
  {
    "url": "https://www.prweb.com/releases/agm-renovations-announces-the-exciting-debut-of-showroom-on-wheels-301942058.html",
    "image": "/images/blog4.jpg"
  },
  {
    "url": "https://www.prweb.com/releases/agm-renovations-announces-new-agm-renovations-program-to-help-homeowners-offset-their-high-mortgage-payment-301954208.html",
    "image": "/images/blog5.jpg"
  },
  {
    "url": "https://techbullion.com/agm-renovations-on-creating-more-living-space-by-finishing-your-basement/",
    "image": "/images/blog7.jpg"
  },
  {
    "url": "https://techbullion.com/agm-renovations-reviews-ways-to-increase-the-value-of-your-home-by-renovating-bathrooms-and-kitchens/",
    "image": "/images/blog8.jpg"
  },
  {
    "url": "https://www.prweb.com/releases/agm-renovations-launches-lucrative-client-referral-program--earn-cash-rewards-and-offer-discounts-to-friends-and-family-302010415.html",
    "image": "/images/blog9.jpg"
  },
  {
    "url": "https://www.brainzmagazine.com/post/delivering-dreams-building-trust-the-agm-renovations-story-with-ivan-atanasov",
    "image": "/images/blog10.jpg"
  },
  {
    "url": "https://www.mostinside.com/kitchen-remodeling-techniques-for-crafting-a-cozy-home-atmosphere",
    "image": "/images/blog11.jpg"
  },
  {
    "url": "https://nywire.com/agm-renovations-reviews-options-for-turning-your-basement-into-a-functional-living-space/",
    "image": "/images/blog12.jpg"
  },
  {
    "url": "https://www.mostinside.com/top-tips-for-turning-your-basement-into-a-rental-suite/",
    "image": "/images/blog13.jpg"
  },
  {
    "url": "https://www.seekerstime.com/agm-renovations-reviews-turning-your-basement-into-a-home-gym/",
    "image": "/images/blog14.jpg"
  },
  {
    "url": "https://www.theamericanreporter.com/agm-renovations-reviews-options-for-remodeling-your-kitchen/",
    "image": "/images/blog15.jpg"
  },
  {
    "url": "https://thechicagojournal.com/agm-renovations-reviews-the-art-of-bathroom-transformation-marrying-style-and-functionality/",
    "image": "/images/blog17.jpg"
  },
  {
    "url": "https://realestatetoday.com/agm-renovations-reviews-ways-to-save-on-home-renovations/",
    "image": "/images/blog16.jpg"
  }
];
