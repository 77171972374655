import React, { useState } from "react";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';


const useStyles = makeStyles({
    rootD: {
      ['& .MuiPaper-root']: {
          overflow: 'visible',
          padding: '0px 0px 10px 0'
      }
    },
    formSection: {

      ['& .MuiTextField-root']: {
        marginBottom: '8px',
      },
      ['& input']: {
        padding: '14px 14px',
      },
      ['& label']: {
        lineHeight: '0.7',
      },

    },
    customIconCloseChat: {
      color: '#19ace4',
      background: '#fff',
      borderRadius: '50%',
        cursor: "pointer",
        position: "absolute",
        right: '2px',
        top: "2px",
        width: '38px',
        height: '38px',
        margin: '-22px -22px 0 0',
    },
  });

const Modal = (props) => {
 
    const classes = useStyles();
// height: calc(100vh - 157px);

    return (
      <Dialog
        open={props.isOpen}
        onClose={props.handleClose}
  
        fullWidth={true}
        className={classes.rootD}
      >
        <DialogContent >
          <section className={classes.formSection}>
            {props.children}
          </section>
        </DialogContent>
        <HighlightOffIcon className={classes.customIconCloseChat} onClick={props.handleClose}/>
      </Dialog>
    );
};

export default Modal;