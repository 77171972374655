import React, { useState } from "react";

import { Link } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';

import LinkItem from "./LinkItem/LinkItem";

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Flooring from "../Assets/images/Landing/svg/flooring.svg";
import Bathroom from "../Assets/images/Landing/svg/bathroom.svg";
import Kitchen from "../Assets/images/Landing/svg/kitchen.svg";
import Basement from "../Assets/images/Landing/svg/basement.svg";
import Painting from "../Assets/images/Landing/svg/painting.svg";
import Windows from "../Assets/images/Landing/svg/windows-doors.svg";
import Roofs from "../Assets/images/Landing/svg/new-roofing.svg";
import Coverings from "../Assets/images/Landing/svg/coverings.svg";
// import PaintingFlooring from "../Assets/images/Landing/svg/Union.svg";
import ClosetOrganizers from "../Assets/images/Landing/svg/closetorg.svg";
import Next from "../Assets/images/Landing/svg/next.svg";
import Additional from "../Assets/images/Landing/svg/additional.svg";

let SERVICES = [
  {
    image: Basement,
    value: "Basement",
    title: "Basement Renovations",
    link: "https://agmbasements.com/",
    availableFor: ["gta"],
    unavailableText: "Service is not available in your city",
  },
  {
    image: Bathroom,
    value: "Bathroom",
    title: "Bathroom Renovations",
    link: "https://bathroomrenovations.com/",
  },
  {
    image: Kitchen,
    value: "Kitchen",
    title: "Kitchen Renovations",
    link: "https://agmkitchens.com/",
  },
  {
    image: Roofs,
    value: "Roofing",
    title: "Roofing",
    link: "https://agmroofs.com/",
  },
  {
    image: Next,
    value: "More Services",
    title: "More Services",
    // visible: 'desktop',
    // availableFor: ["gta"],
    action: 'next',
    optionServices: [
      {
        image: Next,
        value: "Back",
        title: "Back",
        // visible: 'desktop',
        // availableFor: ["gta"],
        action: 'before',
      },
      {
        image: ClosetOrganizers,
        value: "Closet Organizers",
        title: "Custom Closets",
        // availableFor: ["gta"],
        link: "https://agmkitchens.com/custom_closets/?option=closetorg",
        option: 'closetorg',
      },
      {
        image: Windows,
        value: "Windows",
        title: "Windows &_Doors",
        // availableFor: ["gta"],
        link: "https://agmwindows-doors.com/",
      },
      {
        image: Coverings,
        value: "Window Coverings",
        title: "Blinds",
        // availableFor: ["gta"],
        link: "https://agmkitchens.com/blinds/?option=blinds",
        option: 'blinds',
      },
      {
        image: Basement,
        value: "Foundation Cracks Repair",
        title: "Wet Basement",
        // availableFor: ["gta"],
        link: "https://agmcrackrepair.com/",
      },
      {
        image: Additional,
        value: "SunRooms & Home Additions",
        title: "Sunrooms &_Home*Additions",
        // availableFor: ["gta"],
        link: "https://agmhomeadditions.com/",
      }
    ],
    link: "",
  },
];

if (process.env.REACT_APP_NAME === "flooring") {
  SERVICES.splice(4, 0, (
    {
      image: Painting,
      value: "Painting",
      title: "Painting",
      link:  "https://agmpainting.com/",
    }
  ));

} else if (process.env.REACT_APP_NAME === "painting") {
  SERVICES.splice(4, 0, (
    {
      image: Flooring,
      value: "Flooring",
      title: "Flooring &_Stairs",
      link:  "https://agmflooring.com/",
    }
  ));

} else if (process.env.REACT_APP_NAME === "sunroom") {
  SERVICES.splice(4, 0, (
    {
      image: Flooring,
      value: "Flooring",
      title: "Flooring &_Stairs",
      link:  "https://agmflooring.com/",
    }
  ));

  const ms = SERVICES.find( item => item.value === "More Services");

  if (ms && ms.optionServices)
  ms.optionServices[ms.optionServices.length-1] = {
      image: Painting,
      value: "Painting",
      title: "Painting",
      // availableFor: ["non_gta"],
      link:  "https://agmpainting.com/",
  }
}


const useStyles = makeStyles({
    rootD: {
      ['& .MuiPaper-root']: {
          overflow: 'visible',
      }
    },
    customIconCloseChat: {
      color: '#19ace4',
      background: '#fff',
      borderRadius: '50%',
        cursor: "pointer",
        position: "absolute",
        right: '2px',
        top: "2px",
        width: '38px',
        height: '38px',
        margin: '-22px -22px 0 0',
    },
  });

const Modal = (props) => {
 
    const classes = useStyles();
    const [isOpen, setOpen] = useState(false);
    const [services, setServices] = useState(SERVICES);

    // const [image, setImage] = useState('');
    // const [scrollY, setScrollY] = useState(0);

    const handleClose = () => {
      setOpen(false); 
      // setTimeout( e => { window.scrollTo({ top: scrollY }) }, 220);
    };
    
    if (props && props.handler) {
        props.handler.updateState = () => {
            // setScrollY(window.scrollY);
            setOpen(!isOpen);
            // setImage(contentImage);
        };
    }

    const handleOptionServices = (serviceData, action) => {
      setServices(action === 'next' ? serviceData : SERVICES);
    };
    const handleRedirect = (serviceData) => {
      
    };

    return (
      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth={'md'}
        fullWidth={true}
        className={classes.rootD}
        sx={{ zIndex: 2000000000 }}
      >
        <DialogContent >
          <section id="promotinal" className='promoDescription'>
            <h3 style={{ lineHeight: '46px' }}>Don't delay your next projects, <br/>do them now and save BIG.</h3>
            <div className="txtContainer">
              <p>
              All discounts are applicable only when all projects are booked at the same time (can not be combined with any other promotions/offers)
              </p>

              <section className="content">
                <div className="link_list_wrap" style={{ marginBottom: '0px' }}>
                  <ul className="link_list promot">
                    {services.map((itemService, index) => (
                      <LinkItem
                        key={index}
                        {...itemService}
                        onClick={handleRedirect}
                        opeFormOptions={handleOptionServices}
                      />
                    ))}
                  </ul>
                </div>
              </section>

              <div className="get-quote-btn"  onClick={handleClose} style={{ marginTop: "30px" }}>
                <Link to="/get-started" style={{ margin: '0 auto' }} className="blue-btn">
                  Get a Free Quote
                </Link>
              </div>
            </div>
          </section>
        </DialogContent>
        <HighlightOffIcon className={classes.customIconCloseChat} onClick={handleClose}/>
      </Dialog>
    );
};

export default Modal;